import { Pipe, PipeTransform } from '@angular/core';
import { Subject, interval, map } from 'rxjs';

@Pipe({
  name: 'secondsCounter',
  standalone: true
})
export class SecondsCounterPipe implements PipeTransform {
  public _destroySubscriptions$: Subject<void> = new Subject<void>();

  transform(value: Date | number) {
    const startTime = value instanceof Date ? value : new Date(value);

    return interval(100) // Emit every second
      .pipe(
        map(() => (new Date().getTime() - startTime.getTime()) / 1000) // Calculate elapsed seconds,
      );
  }
}
