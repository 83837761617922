import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { UserState } from '../user/state/user.state';
import { ResponseLogItem } from './response-logger.type';
import { ResponseLoggerState } from './state/response-logger.state';
import { map } from 'rxjs';
import { IS_MOBILE } from '@core/misc/misc.utils';

@Component({
  selector: 'app-response-logger',
  templateUrl: './response-logger.component.html',
  styleUrl: './response-logger.component.scss'
})
export class ResponseLoggerComponent {
  public readonly isMobile = IS_MOBILE;
  public logs$ = this.storeService
    .select<ResponseLogItem[]>(ResponseLoggerState.responsesLog)
    .pipe(map((log) => log.map((l) => (l.type === 'progress' ? { ...l, disabled: true } : l))));
  public logsTypedToken!: ResponseLogItem;

  public selectedLogsData = new FormControl<string | null>(null);
  private userData = this.storeService.selectSnapshot(UserState.userData);

  constructor(
    private storeService: StoreService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  public onCopy() {
    if (this.selectedLogsData.value) {
      this.clipboard.copy(this.selectedLogsData.value);
      this.snackBar.open(this.translateService.instant('Data of selected logs saved to clipboard'), '', {
        panelClass: 'snackbar-success',
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }
  }

  public onLogSelect(logs: ResponseLogItem[] | null) {
    if (logs?.length) {
      const logContent: { log: string; type: string; data: unknown }[] = [];
      logs.forEach((e) => {
        logContent.push({
          log: `${e.pageTitle}:${e.apiUrl}:${format(e.requestAt, environment.formats.dateTime)} - ${format(e.responseAt as Date, environment.formats.dateTime)} (${e.duration})`,
          type: e.type,
          data: e
        });
      });

      logContent.push({ log: 'User Data', type: 'info', data: this.userData });

      let connection = (navigator as { connection?: Record<string, unknown> })?.connection;
      if (connection) {
        const { downlink, effectiveType, rtt, saveData } = connection;
        connection = { downlink, effectiveType, rtt, saveData };
      }
      logContent.push({
        log: 'Browsing Data',
        type: 'info',
        data: {
          userAgent: navigator.userAgent,
          languages: navigator.languages,
          dateTimeFormatOptions: Intl.DateTimeFormat().resolvedOptions(),
          connection
        }
      });

      this.selectedLogsData.setValue(JSON.stringify(logContent));
    } else this.selectedLogsData.setValue(null);
  }
}
