@if (logs$ | async; as logs) {
  <div class="p-2 flex flex-col gap-2">
    @if (isMobile) {
      <app-kz-mobile-multi-select-box
        title="Logs"
        optionLabel="apiUrl"
        optionValue="id"
        class="h-[50vh]"
        [typedToken]="logsTypedToken"
        [options]="logs"
        [returnOptionsAsValue]="true"
        (selectedOptions)="onLogSelect($event)"
      >
        <ng-template #templateOptionLabel let-option [typedTemplate]="{ $implicit: logsTypedToken }">
          <div class="text-sm flex flex-row gap-1 items-center">
            <div>{{ option.pageTitle }}</div>
            <div
              class="text-xs"
              [ngClass]="{
                'text-kz-orange-500': option.type === 'progress',
                'text-kz-success': option.type === 'success',
                'text-kz-danger': option.type === 'error'
              }"
            >
              {{ option.apiUrl }}
            </div>

            <div class="text-xs">
              <mat-icon svgIcon="timer" />
              @if (option.duration) {
                {{ option.duration }}
              } @else if (option.requestAt) {
                {{ option.requestAt | secondsCounter | async }}
              }
            </div>
          </div>
        </ng-template>
      </app-kz-mobile-multi-select-box>
    } @else {
      <app-kz-desktop-multi-select-box
        title="Logs"
        optionLabel="apiUrl"
        optionValue="id"
        [typedToken]="logsTypedToken"
        [options]="logs"
        [showAllSelector]="true"
        [returnOptionsAsValue]="true"
        (selectedOptions)="onLogSelect($event)"
      >
        <ng-template #templateOptionLabel let-option [typedTemplate]="{ $implicit: logsTypedToken }">
          <div class="text-sm flex flex-row gap-1 items-center">
            <div>{{ option.pageTitle }}</div>
            <div
              class="text-xs"
              [ngClass]="{
                'text-kz-orange-500': option.type === 'progress',
                'text-kz-success': option.type === 'success',
                'text-kz-danger': option.type === 'error',
                'text-red-800 line-through': option.type === 'cancel'
              }"
            >
              {{ option.apiUrl }}
            </div>

            <div class="text-xs">
              <mat-icon svgIcon="timer" />
              @if (option.duration) {
                {{ option.duration }}
              } @else if (option.requestAt) {
                {{ option.requestAt | secondsCounter | async }}
              }
            </div>
          </div>
        </ng-template>
      </app-kz-desktop-multi-select-box>
    }

    <div>
      <div class="mb-1 flex flex-row justify-end" [style.opacity]="selectedLogsData.value ? 1 : 0">
        <button class="link-btn" type="button" [disabled]="!selectedLogsData.value" (click)="onCopy()">
          {{ 'Copy' | translate }}
        </button>
      </div>
      <mat-form-field class="w-full">
        <mat-label>Data of selected logs</mat-label>
        <textarea matInput rows="3" readonly="true" [formControl]="selectedLogsData"></textarea>
      </mat-form-field>
    </div>
  </div>
}
