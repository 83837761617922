import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { ResponseLogItem } from '@core/modules/response-logger/response-logger.type';

export class responseLoggerSave {
  static readonly type = '[ResponseLogger] Save Log Response';
  constructor(
    public id: string,
    public type: ResponseLogItem['type'],
    public requestAt: Date,
    public request: HttpRequest<unknown>,
    public responseAt?: Date,
    public response?: HttpResponse<unknown> | HttpErrorResponse
  ) {}
}
export class responseLoggerDelete {
  static readonly type = '[ResponseLogger] Delete Log Response';
  constructor(public id: string) {}
}
