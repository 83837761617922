import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ResponseLogItem } from '@core/modules/response-logger/response-logger.type';
import { environment } from '@env/environment';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { timer } from 'rxjs';
import { responseLoggerDelete, responseLoggerSave } from './response-logger.actions';
import { ResponseLoggerStateModel } from './response-logger.model';

const defaults = new ResponseLoggerStateModel();

@State<ResponseLoggerStateModel>({
  name: 'responseLogger',
  defaults
})
@Injectable()
export class ResponseLoggerState {
  constructor(private title: Title) {}
  @Action(responseLoggerSave)
  responseLoggerSave(
    { setState, dispatch }: StateContext<ResponseLoggerStateModel>,
    { id, type, request, requestAt, response, responseAt }: responseLoggerSave
  ) {
    const requestContentType = request.detectContentTypeHeader()?.toLowerCase() ?? 'application/json';
    const responseContentType = response?.headers.get('content-type')?.toLowerCase();

    const responseLog: ResponseLogItem = {
      type,
      id,
      pageTitle: this.title.getTitle(),
      pageUrl: window.location.href,
      apiUrl: request.url,
      apiUrlParams: request.params.toString(),
      method: request.method,
      status: response?.status ?? 0,
      requestAt,
      responseAt,
      responseContentType,
      requestBody: requestContentType?.includes('application/json')
        ? request.body
        : "!!Can't log for this content type",
      requestContentType,
      responseHeaders: response?.headers
        .keys()
        .map((k) => `${k}: ${response.headers.get(k)}`)
        .join(', ')
    };
    if (responseAt) responseLog.duration = (responseAt.getTime() - requestAt.getTime()) / 1000;

    const body = (response as HttpResponse<unknown>)?.body || (response as HttpErrorResponse)?.error;
    if (type === 'error' || environment.responseLogger?.logSuccessResponseBody) responseLog.responseBody = body;
    else if (type === 'success') responseLog.responseBody = '!!Ignored for success results';

    if (type === 'progress')
      setState(
        patch<ResponseLoggerStateModel>({
          responsesLog: insertItem<ResponseLogItem>(responseLog)
        })
      );
    else {
      setState(
        patch<ResponseLoggerStateModel>({
          responsesLog: updateItem<ResponseLogItem>(({ id: sId }) => sId === id, responseLog)
        })
      );

      //delete timer
      if (environment.responseLogger?.deleteTimer)
        timer(environment.responseLogger.deleteTimer).subscribe(() => dispatch(new responseLoggerDelete(id)));
    }
  }
  @Action(responseLoggerDelete)
  responseLoggerDelete({ setState }: StateContext<ResponseLoggerStateModel>, { id }: responseLoggerDelete) {
    setState(
      patch<ResponseLoggerStateModel>({
        responsesLog: removeItem<ResponseLogItem>(({ id: sId }) => sId === id)
      })
    );
  }
  /*  */
  @Selector([ResponseLoggerState])
  static responsesLog({ responsesLog }: ResponseLoggerStateModel) {
    return responsesLog;
  }
}
